@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Poppins !important; */
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1 !important;
}


